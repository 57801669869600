<template>
    <div>
        Info tab
    </div>
</template>

<script>
export default {
    name: "TabInfo",
};
</script>

<style scoped></style>
